import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Layout from "../components/Layout"
import HeaderBack from "../components/HeaderBack"
import SEO from "../components/seo"
import { Container } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Moment from 'react-moment';
import { Link } from "gatsby"

const useStyles = makeStyles({
    container: {
        marginTop: '10px',
        maxWidth: 900,       
        
    },
    card: {
        minWidth: 275,
        backgroundColor: "#fff3e0",
       
    },
    div: {
        textAlign: "center",
        color: "#212121",
        
    },
    bad: {
        fontSize: 30,
        fontWeight: 300,
        textAlign: "justify",
        color: "white",

    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 30,
      width: 800,
    },
    pos: {
      marginBottom: 12,
    },
  });
  
export default function OutlinedCard(props) {

    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const [params, setParams] = useState({})
    const withoutCupon = () => {
        return <Typography  variant="body2" component="p" style={{fontSize: "20px",  margin: "15px",}}>Valor sin cupon $ {params.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</Typography>
    }

    useEffect(() => {
        var url_string = props.location.href;
        var url = new URL(url_string);
        var merchantId = url.searchParams.get("merchantId");
        var message = url.searchParams.get("message");
        var extra1 = url.searchParams.get("extra1");
        var dataExtra = JSON.parse(extra1);
        var TX_VALUE = url.searchParams.get("TX_VALUE");
        var buyerEmail = url.searchParams.get("buyerEmail");
        var amount = url.searchParams.get('amounr');
        var name = url.searchParams.get("extra2");
        var dataExtraName = JSON.parse(name);
        setParams({
            merchantId,
            message,
            TX_VALUE,
            buyerEmail,
            amount,
            name,
            dataExtra,
            dataExtraName
        })
        
    },  )
   
return (
    <Layout>
        <SEO title="Respuesta PayU" />
			<HeaderBack
				title={params.message == "APPROVED"? "TRANSACCION EXITOSA":"HUBO UN PROBLEMA AL REALIZAR EL PAGO"}
				description={params.message == "APPROVED"? "Los pagos realizados se puede validar comuncandose con nosotros": "Lo sentimos!"}
				image={require('./../images/ingresar.jpg')}
			/>
            <Container className={classes.container}>
                <Card className={classes.card} variant="outlined">
                    <CardContent>
                    { params.message == "APPROVED"? 
                        <div className={classes.div}>
                            <CheckCircleIcon style={{ fontSize: 80, color:"#4caf50"}}/>
                            <Typography style={{fontSize: "30px",  margin: "15px"}} color="textPrimary" gutterBottom>
                                {params.dataExtraName.name} {params.dataExtraName.lastname}  
                            </Typography>
                            <Typography style={{fontSize: "20px",  margin: "15px",}} color="textPrimary" gutterBottom>
                                Todo salio como esperabas, ya tu reserva se realizo! 
                            </Typography>
                            <Typography variant="body2" component="p" style={{margin: "15px",}}>
                                Haz recibido en tu email: {params.dataExtraName.email}, un correo con el pago y detalles de la reserva
                            </Typography>
                            <Typography variant="body2" component="p" style={{fontWeight: 600}}>
                                Reserva agendada en el periodo
                            </Typography>
                            <Typography style={{fontSize: "20px"}} color="textPrimary" gutterBottom>
                                Del <Moment format="DD/MM/YYYY"  withTitle>{params.dataExtra.startDate}</Moment> Hasta <Moment format="DD/MM/YYYY" withTitle>{params.dataExtra.endDate}</Moment>
                            </Typography>
                            <Typography  variant="body2" component="p" style={{fontSize: "20px",  margin: "15px",}}>
                                Por un valor de $ {params.TX_VALUE.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
                            </Typography>
                            {params.dataExtra.amount_with_discount? withoutCupon() : ''}         
                        </div>
                        :
                        <div className={classes.div}>
                            <ErrorIcon style={{ fontSize: 80, color:"#dd2c00"}}/>
                            <Typography style={{fontSize: "30px",  margin: "15px",}}>
                                Lo sentimos no pudimos realizar la reserva, vuelve a intentarlo 
                            </Typography>
                        </div>
                    }
                    </CardContent>
                    <Link className="text-dark" to="/">
                        <CardActions>
                            <Button size="small">Volver al inicio</Button>
                        </CardActions>
                    </Link>
                    
                    
                </Card>
            </Container>
    </Layout>

      
    );
  }